<template>
  <div class="AddActivity">
    <div style="text-align: center">
      <h3>{{ids?'编辑活动':'创建活动'}}</h3>
    </div>
    <div class="AddActivity-cnt">
      <div class="AddActivity-cnt-left">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="150px"
          class="demo-ruleForm"
        >
          <el-form-item label="集配中心" prop="logistic_business_id">
            <el-select
              placeholder="选择集配中心"
              @change="hqlist"
              v-model="ruleForm.logistic_business_id"
              clearable
              filterable
              :disabled="$route.query.id?true:false"
            >
              <el-option
                v-for="item of options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="活动类型" prop="type">
            <el-radio-group v-model="type">
              <el-radio :label="1">大促活动</el-radio>
              <!-- <el-radio :label="2">专题活动</el-radio> -->
              <!-- <el-radio :label="3">秒杀活动</el-radio> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item label="活动名称" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="活动时间" prop="start_time">
            <el-date-picker
              v-model="start_time_arr"
              :time-arrow-control="true"
              @change="startchanghe"
              type="datetimerange"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              range-separator="—"
              start-placeholder="活动时间起"
              end-placeholder="活动时间止"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="预提报时间" prop="sign_start_time">
            <el-date-picker
              v-model="sign_start_time_arr"
              :time-arrow-control="true"
              @change="sign_startchanghe"
              type="datetimerange"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              range-separator="—"
              start-placeholder="预提报时间起"
              end-placeholder="预提报时间止"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="活动描述" prop="desc">
            <el-input type="textarea" v-model="ruleForm.desc"></el-input>
          </el-form-item>
          <el-form-item label="活动banner图" prop="banner">
            <b-file-upload
              key="banner"
              :accepts="['jpg', 'jpeg', 'png']"
              :limit="1"
              v-model="head_url_List"
            ></b-file-upload>
          </el-form-item>
          <el-form-item label="是否需采购报品" prop="is_sign">
            <el-radio-group v-model="ruleForm.is_sign">
              <el-radio :label="1">需要</el-radio>
              <el-radio :label="0">不需要</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否需后台审核" prop="is_audit">
            <el-radio-group v-model="ruleForm.is_audit">
              <el-radio :label="1">需要</el-radio>
              <el-radio :label="0">不需要</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="采购限制提报数量" prop="limit_num">
            <el-input-number
              v-model="ruleForm.limit_num"
              :min="0"
              :step="1"
              step-strictly
              label="采购限制提报数量"
            ></el-input-number>
          </el-form-item>

          <!-- <el-form-item label="活动模版" prop="template_id">
            <el-select
              placeholder="选择活动模版"
              v-model="ruleForm.template_id"
              @change="tableDatachange"
              clearable
              filterable
            >
              <el-option
                v-for="item of tableData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="活动状态" prop="open_status">
            <el-radio-group v-model="ruleForm.open_status">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >保存</el-button
            >
            <el-button @click="$router.back()">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- <div class="AddActivity-cnt-right">
        <activitycontent
          :receivedList="modulelist"
          :obj_item="obj_item"
        ></activitycontent>
      </div> -->
    </div>
  </div>
</template>   
<script>
import activitycontent from "./components/activitycontent/index.vue";
import BFileUpload from "@/components/business/BFileUpload/index.vue";
export default {
  name: "AddActivity",
  components: { activitycontent, BFileUpload },
  data() {
    return {
      modulelist: [],
      obj_item: {},
      options: [],
      tableData: [],
      start_time_arr: [],
      sign_start_time_arr: [],
      head_url_List: [],
      type:1,
      ruleForm: {
        logistic_business_id: "", //	T文本	是	集配中心
        // type: 1, //	T文本	是1-大促活动，2-专题活动，3-秒杀活动
        name: "", //	T文本	是	名称
        start_time: "", //	T文本	是	开始时间
        end_time: "", //	T文本	是	结束时间
        sign_start_time: "", //	T文本	是	提报开始时间
        sign_end_time: "", //	T文本	是	提报结束时间
        desc: "", //	T文本	是	描述
        banner: "", //	T文本	是	banner图
        is_sign: 0, //	T文本	是	是否报品 1-需要，0需要
        is_audit: 0, //	T文本	是	是否审核 1-需要，0需要
        limit_num: "", //	T文本	是	提报数量
        open_status: 1, //	T文本	是	开启状态 1-开启，0禁用
        template_id: "", //	T文本	是	模板id
      },
      rules: {
        name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
        logistic_business_id: [
          { required: true, message: "请选择集配中心", trigger: "change" },
        ],

        start_time: [
          { required: true, message: "请选择活动时间", trigger: "change" },
        ],
        // sign_start_time: [
        //   { required: true, message: "请选择预提报时间", trigger: "change" },
        // ],
        // is_sign: [
        //   {
        //     required: true,
        //     message: "请选择是否需采购报品",
        //     trigger: "change",
        //   },
        // ],
        // is_audit: [
        //   {
        //     required: true,
        //     message: "请选择是否需后台审核",
        //     trigger: "change",
        //   },
        // ],
        // template_id: [
        //   { required: true, message: "请选选择活动模版", trigger: "change" },
        // ],
        open_status: [
          { required: true, message: "请选择活动状态", trigger: "change" },
        ],
        // banner: [
        //   {
        //     required: true,
        //     message: "请选上传活动banner图",
        //     trigger: "change",
        //   },
        // ],
      },
      ids:''
    };
  },
  created() {
    this.getBusinessList();
    if(this.$route.query.id){
      this.ids=this.$route.query.id
      this.hqinfo();
    }
  },
  methods: {
    hqinfo(){
this.$api.decoration.promotionactivitydetail({id:this.$route.query.id}).then(res=>{
  this.ruleForm=res.data;
  this.start_time_arr=[this.ruleForm.start_time,this.ruleForm.end_time]
  this.sign_start_time_arr=[this.ruleForm.sign_start_time,this.ruleForm.sign_end_time]
  if(res.data.banner){
    this.head_url_List=[{url:res.data.banner,name:'1111.png'}]
  }

  this.hqlist()
})
    },
    startchanghe(){
      if (this.start_time_arr&&this.start_time_arr.length > 1) {
        this.ruleForm.start_time = this.start_time_arr[0];
        this.ruleForm.end_time = this.start_time_arr[1];
      }else{
        this.ruleForm.start_time ='';
        this.ruleForm.end_time =''
      }
  
    },
    sign_startchanghe(){
      
      if (this.sign_start_time_arr&&this.sign_start_time_arr.length > 1) {
        this.ruleForm.sign_start_time = this.sign_start_time_arr[0];
        this.ruleForm.sign_end_time = this.sign_start_time_arr[1];
      }else{
        this.ruleForm.sign_start_time ='';
        this.ruleForm.sign_end_time =''
      }
    },
    tableDatachange() {
      this.tableData.forEach((el) => {
        if (el.id == this.ruleForm.template_id) {
          this.hqlayout();
        }
      });
    },
    //获取布局页面
    hqlayout() {
      this.$api.decoration
        .template_detail({ id: this.ruleForm.template_id })
        .then((res) => {
          console.log(res, "装修数据返回");
          this.obj_item = res.data;
          this.modulelist = res.data.obj_item ? res.data.obj_item : [];
          this.modulelist.forEach((el, index) => {
            el.configuration.quality.id = res.data.content[index].id;
          });
        });
    },
    hqlist() {
      this.tableData = [];
      this.obj_item = {};
      this.modulelist = [];
      this.$api.decoration
        .template_list({
          is_activity:1,//活动模板列表接口加一个 is_activity 参数，添加或编辑活动时调用值为1，其他为0
          logistic_business_id: this.ruleForm.logistic_business_id,
          activity_id: this.$route.query.id ? this.$route.query.id : "",
          page: 1,
          pageSize: 99999,
        })
        .then((res) => {
          this.tableData = res.data.data;
          
        })
        .catch((err) => {});
    },
    // 获取集配中心数据
    getBusinessList() {
      this.$api.general.businessList({is_online:1}).then((res) => {
        this.options = res.data;
      });
    },
    submitForm(formName) {
      if (this.start_time_arr&&this.start_time_arr.length > 1) {
        this.ruleForm.start_time = this.start_time_arr[0];
        this.ruleForm.end_time = this.start_time_arr[1];
      }
      else{
        this.ruleForm.start_time ='';
        this.ruleForm.end_time =''
      }
      if (this.sign_start_time_arr&&this.sign_start_time_arr.length > 1) {
        this.ruleForm.sign_start_time = this.sign_start_time_arr[0];
        this.ruleForm.sign_end_time = this.sign_start_time_arr[1];
      }
      else{
        this.ruleForm.sign_start_time ='';
        this.ruleForm.sign_end_time =''
      }
      this.ruleForm.banner = this.head_url_List
        ?.map((item) => item.url)
        .join(",");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.$route.query.id){
            this.$api.decoration.promotionactivityedit(this.ruleForm).then((res) => {
            console.log(res, "11111111111111");
            this.$message({
          message: "编辑成功",
          type: "success",
        });
        this.$router.back();
          });
          }else{
            this.$api.decoration.promotionactivityadd(this.ruleForm).then((res) => {
            console.log(res, "11111111111111");
            this.$message({
          message: "添加成功",
          type: "success",
        });
        this.$router.back();
          });
          }
         
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script><style lang="scss">
.AddActivity {
   height: 100%;
  box-sizing: border-box;
  background: #ffffff;
  padding: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: auto;
  .AddActivity-cnt {
    display: flex;
    flex-grow: 1;
    height: 100%;

    overflow-y: auto;
    .AddActivity-cnt-left {
      flex: 3;
      height: 100%;
    }
    .AddActivity-cnt-right {
      //   flex: 2;
      // width: 500px;
      height: 100%;
      display: flex;
      justify-content: center;
      // background-color: #ccc;
    }
  }
}
</style>
    